<template>
  <v-container>
    <v-card class="mx-auto" max-width="50%">
      <v-tabs v-model="tab" background-color="primary" dark fixed-tabs>
        <v-tab>Typy pamiątek</v-tab>
        <v-tab>Typy materiałów</v-tab>
        <v-tab>Dziedziny</v-tab>
        <v-tab>Tagi</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card>
            <ItemTypesDataTable></ItemTypesDataTable>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card>
            <MaterialTypesDataTable></MaterialTypesDataTable>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card>
            <OccastionTypes></OccastionTypes>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card>
            <TagsDataTable></TagsDataTable>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import TagsDataTable from "@/components/TagsDataTable.vue";
import ItemTypesDataTable from "@/components/ItemTypesDataTable.vue";
import MaterialTypesDataTable from "@/components/MaterialTypesDataTable.vue";
import OccastionTypes from "@/components/OccasionTypesDataTable.vue";

export default {
  name: "Dictionaries",
  components: {
    TagsDataTable,
    ItemTypesDataTable,
    MaterialTypesDataTable,
    OccastionTypes,
  },
  data() {
    return {
      tab: null,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>