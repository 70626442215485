<template>
  <div>
    <NavBar :drawer="drawer" />

    <v-app-bar app dense color="primary">
      <v-app-bar-nav-icon
        color="white"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <!-- <a href="http://datajana.pl/">
        <v-img
          :src="require('@/assets/logo_transparent_small.png')"
          contain
          max-width="170"
        ></v-img>
      </a> -->
      <router-link to="/"
        ><v-btn background-color="primary" color="white" plain
          >Strona Główna</v-btn
        ></router-link
      >
      <router-link v-if="isAdmin || isRegular" to="/newItem"
        ><v-btn background-color="primary" color="white" plain
          >Nowa Pamiątka</v-btn
        ></router-link
      >
      <!-- <router-link v-if="isAdmin || isRegular" to="/occasions"
        ><v-btn background-color="primary" color="white" plain
          >Imprezy</v-btn
        ></router-link
      > -->
      <router-link v-if="isAdmin || isRegular" to="/dictionaries"
        ><v-btn background-color="primary" color="white" plain
          >Słowniki</v-btn
        ></router-link
      >
      <router-link v-if="isAdmin" to="/users"
        ><v-btn background-color="primary" color="white" plain
          >Użytkownicy</v-btn
        ></router-link
      >

      <v-spacer></v-spacer>
      <!-- <v-toolbar dense tile color="primary" elevation="0"> -->
      <v-row align="center">
        <v-text-field
          v-model="searchText"
          append-icon="mdi-magnify"
          placeholder="Wyszukaj..."
          color="white"
          hide-details
          dark
          clearable
          @click:append="confirmSimpleSearch"
          @keydown.enter="confirmSimpleSearch"
        ></v-text-field>
        <!-- <v-tooltip bottom> -->
        <v-menu
          ref="menu"
          v-model="showAdvancedSearch"
          :close-on-content-click="false"
          offset-y
        >
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                  <v-icon v-if="!showAdvancedSearch" color="white"
                    >mdi-chevron-down</v-icon
                  >
                  <v-icon v-else color="white">mdi-chevron-up</v-icon>
                </v-btn>
              </template>
              <span>Zaawansowane</span>
            </v-tooltip>
          </template>

          <v-card @keydown.enter="confirmSearch">
            <!-- <v-card-text>Eksponat</v-card-text> -->
            <v-list>
              <v-list-item>
                <v-text-field
                  dense
                  label="Sygnatura"
                  v-model="searchItemSignature"
                ></v-text-field>
              </v-list-item>
              <v-list-item>
                <v-text-field
                  dense
                  label="Nazwa"
                  v-model="searchItemName"
                ></v-text-field>
              </v-list-item>
              <v-list-item>
                <v-text-field
                  dense
                  label="Treść"
                  v-model="searchItemContent"
                ></v-text-field>
              </v-list-item>
              <v-list-item>
                <v-text-field
                  dense
                  label="Dodatkowe informacje"
                  v-model="searchItemAdditionalInfo"
                ></v-text-field>
              </v-list-item>
              <v-list-item>
                <v-text-field
                  dense
                  label="Lokalizacja"
                  v-model="searchItemLocation"
                ></v-text-field>
              </v-list-item>
              <v-list-item>
                <TextFieldAutocompleteForm
                  ref="searchMaterialTypesForm"
                  label="Materiał wykonania"
                  :items="materialTypes"
                  itemText="name"
                  itemValue="id"
                  @valueChanged="setValue('searchMaterialType', $event)"
                ></TextFieldAutocompleteForm>
                <TextFieldAutocompleteForm
                  ref="searchItemTypeForm"
                  label="Typ eksponatu"
                  :items="itemTypes"
                  itemText="name"
                  itemValue="id"
                  @valueChanged="setValue('searchItemType', $event)"
                ></TextFieldAutocompleteForm>
              </v-list-item>
              <v-list-item>
                <TextFieldAutocompleteForm
                  ref="searchOccasionForm"
                  label="Cykl"
                  :items="occasions"
                  itemText="name"
                  itemValue="id"
                  @valueChanged="setValue('searchOccasion', $event)"
                ></TextFieldAutocompleteForm>
              </v-list-item>
              <v-list-item>
                <TextFieldAutocompleteForm
                  ref="searchItemOrganizerForm"
                  label="Organizator"
                  :items="organizers"
                  itemText="name"
                  itemValue="id"
                  @valueChanged="setValue('searchItemOrganizer', $event)"
                ></TextFieldAutocompleteForm>
              </v-list-item>
              <v-list-item>
                <TextFieldAutocompleteForm
                  ref="searchItemOccasionTypeForm"
                  label="Dziedzina"
                  :items="occasionTypes"
                  itemText="name"
                  itemValue="id"
                  @valueChanged="setValue('searchItemOccasionType', $event)"
                ></TextFieldAutocompleteForm>
              </v-list-item>
              <!-- <v-list-item>
                <TextFieldAutocompleteForm
                  ref="searchItemOccasionYearFromForm"
                  label="Rok imprezy"
                  :items="years"
                  @valueChanged="setValue('searchItemOccasionYearFrom', $event)"
                ></TextFieldAutocompleteForm>
                <TextFieldAutocompleteForm
                  ref="searchItemOccasionYearToForm"
                  label="Rok imprezy"
                  :items="years"
                  @valueChanged="setValue('searchItemOccasionYearTo', $event)"
                ></TextFieldAutocompleteForm>
              </v-list-item> -->
            </v-list>
            <v-divider></v-divider>
            <v-list v-if="userLoggedIn">
              <v-list-item>
                <v-text-field
                  dense
                  label="Autor"
                  v-model="searchItemAuthor"
                ></v-text-field>
              </v-list-item>
              <v-list-item>
                <v-text-field
                  dense
                  label="Darczyńca"
                  v-model="searchItemDonor"
                ></v-text-field>
              </v-list-item>
              <v-list-item>
                <v-text-field
                  dense
                  label="Uwagi"
                  v-model="searchItemComments"
                ></v-text-field>
              </v-list-item>
              <!-- <v-card-text>Impreza</v-card-text> -->

              <!-- <v-list-item>
                <TextFieldAutocompleteForm
                  ref="searchItemOccasionTypeForm"
                  label="Rodzaj*"
                  :items="occasionTypes"
                  itemText="name"
                  itemValue="id"
                  @valueChanged="setValue('searchItemOccasionType', $event)"
                ></TextFieldAutocompleteForm>
              </v-list-item> -->
            </v-list>
            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn text @click="closeAdvancedSearch"> Zamknij </v-btn>
              <v-btn color="primary" text @click="confirmSearch">
                Wyszukaj
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
        <v-menu v-if="userLoggedIn" bottom min-width="200px" rounded offset-y>
          <template v-slot:activator="{ on }">
            <v-btn icon x-large v-on="on">
              <v-avatar color="brown" size="40">
                <span class="white--text text-h6">{{ userInfo.initials }}</span>
              </v-avatar>
            </v-btn>
          </template>
          <v-card>
            <v-list-item-content v-if="userLoggedIn" class="justify-center">
              <!-- <v-list-item-content class="justify-center"> -->
              <div class="mx-auto text-center">
                <v-avatar color="brown">
                  <span class="white--text text-h5">{{
                    userInfo.initials
                  }}</span>
                </v-avatar>
                <h3>{{ userInfo.fullName }}</h3>
                <p class="text-caption mt-1">
                  {{ userInfo.email }}
                </p>
                <v-divider class="my-3"></v-divider>
                <v-btn depressed rounded text @click="logoutUser">
                  Wyloguj
                </v-btn>
              </div>
            </v-list-item-content>
          </v-card>
        </v-menu>
      </v-row>
    </v-app-bar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  getItemTypes,
  getOrganizers,
  getOccasions,
  getOccasionTypes,
  getMaterialTypes,
} from "@/shared/api";
import { getYearsRange } from "@/shared/utils/dates";
import LocalStorage from "@/shared/utils/localStorage.js";
import NavBar from "@/components/NavBar.vue";
import TextFieldAutocompleteForm from "@/components/forms/TextFieldAutocompleteForm.vue";

export default {
  name: "HeaderBar",
  components: { NavBar, TextFieldAutocompleteForm },
  data() {
    return {
      drawer: true,
      showAdvancedSearch: false,
      searchText: "",
      itemTypes: null,
      organizers: null,
      occasionTypes: null,
      materialTypes: null,
      searchItemSignature: null,
      searchItemName: null,
      searchItemContent: null,
      searchItemAdditionalInfo: null,
      searchMaterialType: null,
      searchItemType: null,
      searchItemLocation: null,
      searchOccasion: null,
      searchItemAuthor: null,
      searchItemDonor: null,
      searchItemComments: null,
      searchItemOccasionOrganizer: null,
      searchItemOccasionType: null,
      // searchItemOccasionYearFrom: null,
      // searchItemOccasionYearTo: null,
    };
  },
  async created() {
    this.itemTypes = await getItemTypes();
    this.organizers = await getOrganizers();
    this.occasions = await getOccasions();
    this.occasionTypes = await getOccasionTypes();
    this.materialTypes = await getMaterialTypes();
  },
  computed: {
    ...mapGetters(["userInfo", "userLoggedIn"]),
    years() {
      return getYearsRange(1980);
    },
    isAdmin() {
      if (!this.userLoggedIn) {
        return false;
      }
      const user = LocalStorage.get("user");
      return user.role === "ADMIN";
    },
    isRegular() {
      if (!this.userLoggedIn) {
        return false;
      }
      const user = LocalStorage.get("user");
      return user.role === "REGULAR";
    },
  },
  methods: {
    ...mapActions(["logout"]),
    logoutUser() {
      this.logout();
      this.$router.push("/");
    },
    setValue(property, value) {
      this[property] = value;
    },
    confirmSimpleSearch() {
      if (this.searchText) {
        this.$router.push({
          path: `/search/`,
          query: { text: this.searchText },
        });
      }
    },
    confirmSearch() {
      var query = this.createSearchQuery();
      this.$router.push({ path: `/search/`, query: query });
      this.clearAdvancedSearch();
      this.closeAdvancedSearch();
    },
    createSearchQuery() {
      var query = {};

      if (this.searchItemSignature !== null) {
        query = { ...query, signature: this.searchItemSignature };
      }
      if (this.searchItemName !== null) {
        query = { ...query, name: this.searchItemName };
      }
      if (this.searchItemContent !== null) {
        query = { ...query, content: this.searchItemContent };
      }
      if (this.searchItemAdditionalInfo !== null) {
        query = { ...query, additional_info: this.searchItemAdditionalInfo };
      }
      if (this.searchItemLocation !== null) {
        query = { ...query, location: this.searchItemLocation };
      }
      if (this.searchMaterialType !== null) {
        query = { ...query, material_id: this.searchMaterialType };
      }
      if (this.searchItemType !== null) {
        query = { ...query, item_type_id: this.searchItemType };
      }
      if (this.searchOccasion !== null) {
        query = { ...query, occasion_id: this.searchOccasion };
      }
      if (this.searchItemOrganizer !== null) {
        query = {
          ...query,
          organizer_id: this.searchItemOrganizer,
        };
      }
      if (this.searchItemOccasionType !== null) {
        query = {
          ...query,
          occasion_type_id: this.searchItemOccasionType,
        };
      }

      if (this.searchItemAuthor !== null) {
        query = { ...query, author: this.searchItemAuthor };
      }
      if (this.searchItemDonor !== null) {
        query = { ...query, donor: this.searchItemDonor };
      }
      if (this.searchItemComments !== null) {
        query = { ...query, comments: this.searchItemComments };
      }
      // if (this.searchItemOccasionYearFrom !== null) {
      //   query = {
      //     ...query,
      //     occasion_year_from: this.searchItemOccasionYearFrom,
      //   };
      // }
      // if (this.searchItemOccasionYearTo !== null) {
      //   query = {
      //     ...query,
      //     occasion_year_to: this.searchItemOccasionYearTo,
      //   };
      // }

      return query;
    },
    clearAdvancedSearch() {
      this.searchItemSignature = null;
      this.searchItemName = null;
      this.searchItemContent = null;
      this.searchItemAdditionalInfo = null;
      this.searchItemType = null;
      this.searchItemLocation = null;
      this.searchItemAuthor = null;
      this.searchItemDonor = null;
      this.searchItemComments = null;

      this.$refs.searchMaterialTypesForm.value = null;
      this.$refs.searchItemTypeForm.value = null;
      this.$refs.searchOccasionForm.value = null;
      this.$refs.searchItemOrganizerForm.value = null;
      this.$refs.searchItemOccasionTypeForm.value = null;
      // this.$refs.searchItemOccasionYearFromForm.value = null;
      // this.$refs.searchItemOccasionYearToForm.value = null;
    },
    closeAdvancedSearch() {
      this.showAdvancedSearch = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>