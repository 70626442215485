<template>
  <v-container>
    <v-card>
      <v-card-title>
        Lista imprez
        <v-spacer></v-spacer>
        <!-- <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Wyszukaj"
        single-line
        hide-details
      ></v-text-field> -->
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="occasions"
        :search="search"
        :loading="loading"
        loading-text="Pobieranie imprez... Proszę czekać"
        multi-sort
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Wyszukaj"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  Dodaj imprezę
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="4" md="12">
                        <v-text-field
                          v-model="editedItem.name"
                          label="Nazwa"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" md="4">
                        <v-autocomplete
                          v-model="editedItem.year"
                          label="Rok*"
                          :items="years"
                          :valueDefault="editedItem.year"
                          @valueChanged="
                            setObjectValue('editedItem', 'year', $event)
                          "
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="4" md="8">
                        <v-autocomplete
                          v-model="editedItem.voivodeship"
                          label="Województwo*"
                          :items="voivodeshipList"
                          :valueDefault="editedItem.voivodeship"
                          itemText="name"
                          itemValue="id"
                          @valueChanged="
                            setObjectValue('editedItem', 'voivodeship', $event)
                          "
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="4" md="12">
                        <v-autocomplete
                          v-model="editedItem.occasion_cyclical"
                          label="Cykl"
                          :items="occasionsCyclical"
                          :valueDefault="editedItem.occasion_cyclical"
                          itemText="name"
                          itemValue="id"
                          @valueChanged="
                            setObjectValue(
                              'editedItem',
                              'occasion_cyclical',
                              $event
                            )
                          "
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="4" md="12">
                        <v-autocomplete
                          v-model="editedItem.occasion_types"
                          label="Rodzaj*"
                          :items="occasionTypes"
                          :valueDefault="editedItem.occasion_types"
                          :multiple="true"
                          itemText="name"
                          itemValue="id"
                          @valueChanged="
                            setObjectValue(
                              'editedItem',
                              'occasion_types',
                              $event
                            )
                          "
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="4" md="12">
                        <v-autocomplete
                          v-model="editedItem.pttk_section"
                          label="Oddział PTTK*"
                          :items="pttkSections"
                          :valueDefault="editedItem.pttk_section"
                          itemText="name"
                          itemValue="id"
                          color="green darken-2"
                          clearable
                          @valueChanged="
                            setObjectValue('editedItem', 'pttk_section', $event)
                          "
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="4" md="12">
                        <v-autocomplete
                          v-model="editedItem.occasion_organizers"
                          label="Organizatorzy"
                          :items="organizers"
                          :valueDefault="editedItem.occasion_organizers"
                          :multiple="true"
                          itemText="name"
                          itemValue="id"
                          @valueChanged="
                            setObjectValue(
                              'editedItem',
                              'occasion_organizers',
                              $event
                            )
                          "
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="4" md="12">
                        <v-text-field
                          v-model="editedItem.localization"
                          label="Lokalizacja"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    Anuluj
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="createOrUpdateOccasion"
                  >
                    Zapisz
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5"
                  >Czy na pewno chcesz usunąć tę imprezę?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >Anuluj</v-btn
                  >
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="deleteOccasionConfirm"
                    >Usuń</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.occasion_types="{ item }">
          <v-chip
            v-for="type in item.occasion_types"
            :key="type.id"
            color="gray"
            dense
          >
            {{ type.name }}
          </v-chip>
        </template>
        <template v-slot:item.occasion_organizers="{ item }">
          <v-chip
            v-for="organizer in item.occasion_organizers"
            :key="organizer.id"
            color="gray"
            dense
          >
            {{ organizer.name }}
          </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editOccasion(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteOccasion(item)"> mdi-delete </v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="initialize"> Reset </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { nanoid } from "nanoid";
import {
  getOccasions,
  createOccasion,
  updateOccasion,
  deleteOccasion,
  getVoivodeshipList,
  getOccasionsCyclical,
  getOccasionTypes,
  getPttkSections,
  getOrganizers,
} from "@/shared/api";
import { getYearsRange } from "@/shared/utils/dates";

export default {
  name: "OccasionsList",
  components: {},
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      search: "",
      loading: false,
      headers: [
        {
          text: "IMPREZA",
          align: "start",
          value: "name",
        },
        { text: "ROK", value: "year" },
        { text: "LOKALIZACJA", value: "localization" },
        { text: "CYKL", value: "occasion_cyclical.name" },
        { text: "RODZAJ", value: "occasion_types" },
        { text: "ORGANIZATORZY", value: "occasion_organizers" },
        { text: "SEKCJA PTTK", value: "pttk_section.name" },
        { text: "WOJEWÓDZTWO", value: "voivodeship.name" },
        { text: "AKCJE", value: "actions", sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        name: null,
        year: null,
        voivodeship: null,
        pttk_section: null,
        occasion_cyclical: null,
        occasion_types: null,
        occasion_organizers: null,
        localization: null,
      },
      defaultItem: {
        name: null,
        year: null,
        voivodeship: null,
        pttk_section: null,
        occasion_cyclical: null,
        occasion_types: null,
        occasion_organizers: null,
        localization: null,
      },
      occasions: [],
      voivodeshipList: null,
      pttkSections: null,
      occasionsCyclical: null,
      occasionTypes: null,
      organizers: null,
    };
  },
  async created() {
    this.loading = true;
    await this.initialize();
    this.loading = false;
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nowa impreza" : "Edycja imprezy";
    },
    years() {
      return getYearsRange(1980);
    },
  },
  methods: {
    async initialize() {
      this.occasions = await getOccasions();
      this.occasionsCyclical = await getOccasionsCyclical();
      this.occasionTypes = await getOccasionTypes();
      this.voivodeshipList = await getVoivodeshipList();
      this.pttkSections = await getPttkSections();
      this.organizers = await getOrganizers();
    },
    setObjectValue(object, property, value) {
      this[object][property] = value;
    },
    editOccasion(item) {
      this.editedIndex = this.occasions.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteOccasion(item) {
      this.editedIndex = this.occasions.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    async deleteOccasionConfirm() {
      await deleteOccasion(this.editedItem.id);
      this.occasions.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async createOrUpdateOccasion() {
      if (this.editedIndex > -1) {
        await this.updateOccasion();
      } else {
        await this.createOccasion();
      }
    },
    async createOccasion() {
      const newOccasionId = nanoid(16);
      var payload = this.createPayload();
      payload = { id: newOccasionId, ...payload };

      await createOccasion(payload);
      this.close();
      this.occasions = await getOccasions();
    },
    async updateOccasion() {
      var payload = this.createPayload();

      await updateOccasion(this.editedItem.id, payload);

      if (typeof this.editedItem.pttk_section !== "object") {
        this.editedItem.pttk_section = this.pttkSections.find(
          (pttkSection) => pttkSection.id == this.editedItem.pttk_section
        );
      }
      if (typeof this.editedItem.voivodeship !== "object") {
        this.editedItem.voivodeship = this.voivodeshipList.find(
          (voivodeship) => voivodeship.id == this.editedItem.voivodeship
        );
      }
      if (typeof this.editedItem.occasion_cyclical !== "object") {
        this.editedItem.occasion_cyclical = this.occasionsCyclical.find(
          (occasion) => occasion.id == this.editedItem.occasion_cyclical
        );
      }
      this.editedItem.occasion_types = this.editedItem.occasion_types.map(
        (oc) =>
          typeof oc !== "object"
            ? this.occasionTypes.find((ot) => ot.id === oc)
            : oc
      );
      this.editedItem.occasion_organizers =
        this.editedItem.occasion_organizers.map((oo) =>
          typeof oo !== "object" ? this.organizers.find((o) => o.id === oo) : oo
        );

      this.close();
      Object.assign(this.occasions[this.editedIndex], this.editedItem);
    },
    createPayload() {
      var payload = {
        name: this.editedItem.name,
        year: this.editedItem.year,
        localization: this.editedItem.localization,
        pttk_section_id:
          typeof this.editedItem.pttk_section === "object"
            ? this.editedItem.pttk_section.id
            : this.editedItem.pttk_section,
        voivodeship_id:
          typeof this.editedItem.voivodeship === "object"
            ? this.editedItem.voivodeship.id
            : this.editedItem.voivodeship,
        occasion_cyclical:
          typeof this.editedItem.occasion_cyclical === "object" &&
          this.editedItem.occasion_cyclical !== null
            ? this.editedItem.occasion_cyclical.id
            : this.editedItem.occasion_cyclical,
        occasion_type_ids: this.editedItem.occasion_types.map((oc) =>
          typeof oc === "object" ? oc.id : oc
        ),
        occasion_organizer_ids: this.editedItem.occasion_organizers.map((oo) =>
          typeof oo === "object" ? oo.id : oo
        ),
      };
      return payload;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>