var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',[_c('v-card-title',[_vm._v(" Lista imprez "),_c('v-spacer')],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.occasions,"search":_vm.search,"loading":_vm.loading,"loading-text":"Pobieranie imprez... Proszę czekać","multi-sort":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Wyszukaj","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Dodaj imprezę ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"12"}},[_c('v-text-field',{attrs:{"label":"Nazwa"},model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"editedItem.name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-autocomplete',{attrs:{"label":"Rok*","items":_vm.years,"valueDefault":_vm.editedItem.year},on:{"valueChanged":function($event){return _vm.setObjectValue('editedItem', 'year', $event)}},model:{value:(_vm.editedItem.year),callback:function ($$v) {_vm.$set(_vm.editedItem, "year", $$v)},expression:"editedItem.year"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"8"}},[_c('v-autocomplete',{attrs:{"label":"Województwo*","items":_vm.voivodeshipList,"valueDefault":_vm.editedItem.voivodeship,"itemText":"name","itemValue":"id"},on:{"valueChanged":function($event){return _vm.setObjectValue('editedItem', 'voivodeship', $event)}},model:{value:(_vm.editedItem.voivodeship),callback:function ($$v) {_vm.$set(_vm.editedItem, "voivodeship", $$v)},expression:"editedItem.voivodeship"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"12"}},[_c('v-autocomplete',{attrs:{"label":"Cykl","items":_vm.occasionsCyclical,"valueDefault":_vm.editedItem.occasion_cyclical,"itemText":"name","itemValue":"id"},on:{"valueChanged":function($event){return _vm.setObjectValue(
                            'editedItem',
                            'occasion_cyclical',
                            $event
                          )}},model:{value:(_vm.editedItem.occasion_cyclical),callback:function ($$v) {_vm.$set(_vm.editedItem, "occasion_cyclical", $$v)},expression:"editedItem.occasion_cyclical"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"12"}},[_c('v-autocomplete',{attrs:{"label":"Rodzaj*","items":_vm.occasionTypes,"valueDefault":_vm.editedItem.occasion_types,"multiple":true,"itemText":"name","itemValue":"id"},on:{"valueChanged":function($event){return _vm.setObjectValue(
                            'editedItem',
                            'occasion_types',
                            $event
                          )}},model:{value:(_vm.editedItem.occasion_types),callback:function ($$v) {_vm.$set(_vm.editedItem, "occasion_types", $$v)},expression:"editedItem.occasion_types"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"12"}},[_c('v-autocomplete',{attrs:{"label":"Oddział PTTK*","items":_vm.pttkSections,"valueDefault":_vm.editedItem.pttk_section,"itemText":"name","itemValue":"id","color":"green darken-2","clearable":""},on:{"valueChanged":function($event){return _vm.setObjectValue('editedItem', 'pttk_section', $event)}},model:{value:(_vm.editedItem.pttk_section),callback:function ($$v) {_vm.$set(_vm.editedItem, "pttk_section", $$v)},expression:"editedItem.pttk_section"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"12"}},[_c('v-autocomplete',{attrs:{"label":"Organizatorzy","items":_vm.organizers,"valueDefault":_vm.editedItem.occasion_organizers,"multiple":true,"itemText":"name","itemValue":"id"},on:{"valueChanged":function($event){return _vm.setObjectValue(
                            'editedItem',
                            'occasion_organizers',
                            $event
                          )}},model:{value:(_vm.editedItem.occasion_organizers),callback:function ($$v) {_vm.$set(_vm.editedItem, "occasion_organizers", $$v)},expression:"editedItem.occasion_organizers"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"12"}},[_c('v-text-field',{attrs:{"label":"Lokalizacja"},model:{value:(_vm.editedItem.localization),callback:function ($$v) {_vm.$set(_vm.editedItem, "localization", $$v)},expression:"editedItem.localization"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Anuluj ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.createOrUpdateOccasion}},[_vm._v(" Zapisz ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Czy na pewno chcesz usunąć tę imprezę?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Anuluj")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteOccasionConfirm}},[_vm._v("Usuń")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.occasion_types",fn:function(ref){
                          var item = ref.item;
return _vm._l((item.occasion_types),function(type){return _c('v-chip',{key:type.id,attrs:{"color":"gray","dense":""}},[_vm._v(" "+_vm._s(type.name)+" ")])})}},{key:"item.occasion_organizers",fn:function(ref){
                          var item = ref.item;
return _vm._l((item.occasion_organizers),function(organizer){return _c('v-chip',{key:organizer.id,attrs:{"color":"gray","dense":""}},[_vm._v(" "+_vm._s(organizer.name)+" ")])})}},{key:"item.actions",fn:function(ref){
                          var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editOccasion(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteOccasion(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v(" Reset ")])]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }