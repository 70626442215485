<template>
  <v-text-field
    v-model="value"
    :label="label"
    :readonly="readonly"
    :disabled="disabled"
    :rules="rules"
    :placeholder="placeholder"
    :counter="counter"
    color="green darken-2"
    dense
    outlined
  >
  </v-text-field>
</template>

<script>
export default {
  name: "TextFieldForm",
  props: {
    label: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    rules: {
      type: Array,
      required: false,
    },
    placeholder: {
      type: String,
      default: null,
      required: false,
    },
    counter: {
      type: Number,
      default: null,
      required: false,
    },
    valueDefault: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      value: null,
    };
  },
  created() {
    console.log(this.valueDefault);
    if (this.valueDefault) {
      this.value = this.valueDefault;
    }
  },
  watch: {
    value(newValue) {
      this.$emit("valueChanged", newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>