import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import ItemDetails from '../views/ItemDetails.vue'
import Dictionaries from '../views/Dictionaries.vue'
import EditItem from '../views/EditItem.vue'
import NewItem from '../views/NewItem.vue'
import Search from '../views/Search.vue'
import OccasionsList from '../views/OccasionsList.vue'
import Users from '../views/Users.vue'
import LocalStorage from "@/shared/utils/localStorage.js";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login/:nextPath?',
    name: 'Login',
    props: true,
    component: Login
  },
  {
    path: '/search/',
    name: 'Search',
    component: Search
  },
  {
    path: '/item/:id',
    name: 'ItemDetails',
    component: ItemDetails
  },
  {
    path: '/newItem',
    name: 'NewItem',
    component: NewItem
  },
  {
    path: '/editItem/:id',
    name: 'EditItem',
    component: EditItem
  },
  {
    path: '/dictionaries',
    name: 'Dictionaries',
    component: Dictionaries
  },
  {
    path: '/occasions',
    name: 'OccasionsList',
    component: OccasionsList
  },
  {
    path: '/users',
    name: 'Users',
    component: Users
  },
  {
    path: '/news',
    name: 'News',
    component: () => import(/* webpackChunkName: "news" */ '../views/News.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/history',
    name: 'History',
    component: () => import(/* webpackChunkName: "history" */ '../views/History.vue')
  },
  {
    path: '/regulations',
    name: 'Regulations',
    component: () => import(/* webpackChunkName: "regulations" */ '../views/Regulations.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  const privatePages = ['/newItem', '/editItem', '/dictionaries', '/occasions', '/users'];
  const authRequired = privatePages.includes(to.path);
  const token = LocalStorage.get('refreshToken')
  const loggedIn = token !== null;

  if (authRequired && !loggedIn) {
    LocalStorage.remove('accessToken')
    LocalStorage.remove('user')
    next({ name: 'Login', params: { nextPath: to.fullPath }, replace: true });
  } else {
    next();
  }
});


export default router
