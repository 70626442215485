<template>
  <v-container fluid fill-height @keydown.enter="logInUserAndProceed">
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Logowanie</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-text-field
                v-model="username"
                prepend-icon="mdi-account"
                name="login"
                label="Login"
                type="text"
              ></v-text-field>
              <v-text-field
                v-model="password"
                id="password"
                prepend-icon="mdi-lock"
                name="password"
                label="Hasło"
                type="password"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="logInUserAndProceed">Zaloguj</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "Login",
  props: {
    nextPath: {
      type: String,
      required: false,
      default: "/",
    },
  },
  data() {
    return {
      username: null,
      password: null,
    };
  },
  methods: {
    ...mapActions(["login"]),
    async logInUserAndProceed() {
      await this.login({ username: this.username, password: this.password });
      this.routeNext();
    },
    routeNext() {
      // if (this.nextPath === "/") {
      // this.$router.go(this.nextPath);
      // } else {
      this.$router.replace({ path: this.nextPath });
      // }
    },
  },
};
</script>

<style></style>