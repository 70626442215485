<template>
  <!-- <v-row height="900px"> -->
  <v-row>
    <v-col cols="12" md="7">
      <v-row>
        <v-col>
          <v-card elevation="24" max-width="50%" class="ma-auto image-carousel">
            <v-carousel
              height="50%"
              hide-delimiter-background
              show-arrows-on-hover
            >
              <v-carousel-item v-for="image in images" :key="image.id">
                <v-img
                  :src="image.data"
                  aspect-ratio="1"
                  contain
                  class="grey lighten-2"
                >
                </v-img>
              </v-carousel-item>
            </v-carousel> </v-card></v-col
      ></v-row>
      <v-row v-if="userLoggedIn"
        ><v-col cols="12" class="d-flex justify-center">
          <router-link :to="itemEditLink">
            <v-btn small color="error">Edytuj</v-btn>
          </router-link>
        </v-col></v-row
      >
    </v-col>
    <v-col cols="12" md="5">
      <v-navigation-drawer absolute permanent right width="30%" dark>
        <v-card
          ><v-card-title class="text-body-1" dense>Sygnatura:</v-card-title
          ><v-card-text class="text-body-2" dense>{{
            item.signature
          }}</v-card-text></v-card
        >
        <v-card
          ><v-card-title class="text-body-1" dense>Nazwa:</v-card-title
          ><v-card-text class="text-body-2" dense>{{
            item.name
          }}</v-card-text></v-card
        >
        <v-card
          ><v-card-title class="text-body-1" dense>Typ:</v-card-title
          ><v-card-text class="text-body-2" dense>{{
            item.item_type.name
          }}</v-card-text></v-card
        >
        <v-card
          ><v-card-title class="text-body-1" dense
            >Materiał wykonania:</v-card-title
          ><v-card-text class="text-body-2" dense>{{
            item.material_type === null ? "-" : item.material_type.name
          }}</v-card-text></v-card
        >
        <v-card
          ><v-card-title class="text-body-1" dense>Cykl:</v-card-title
          ><v-card-text class="text-body-2" dense>{{
            item.occasion === null ? "-" : item.occasion.name
          }}</v-card-text></v-card
        >
        <v-card
          ><v-card-title class="text-body-1" dense>Rok:</v-card-title
          ><v-card-text class="text-body-2" dense>{{
            item.occasion_year === null ? "-" : item.occasion_year
          }}</v-card-text></v-card
        >
        <v-card
          ><v-card-title class="text-body-1" dense>Lokalizacja:</v-card-title
          ><v-card-text class="text-body-2" dense>{{
            item.location === null ? "-" : item.location
          }}</v-card-text></v-card
        >
        <v-card
          ><v-card-title class="text-body-1" dense>Organizatorzy:</v-card-title
          ><v-card-text class="text-body-2" dense>{{
            organizers === null ? "-" : organizers
          }}</v-card-text></v-card
        >
        <v-card
          ><v-card-title class="text-body-1" dense>Dziedzina:</v-card-title
          ><v-card-text class="text-body-2" dense>{{
            occasionTypes === null ? "-" : occasionTypes
          }}</v-card-text></v-card
        >
        <!-- <v-card
          ><v-card-title class="text-body-1" dense>Województwo:</v-card-title
          ><v-card-text class="text-body-2" dense>{{
            occasion.voivodeship.name
          }}</v-card-text></v-card
        >
        <v-card
          ><v-card-title class="text-body-1" dense>Organizatorzy:</v-card-title
          ><v-card-text class="text-body-2" dense>{{
            occasionOrganizers
          }}</v-card-text></v-card
        >
        <v-card
          ><v-card-title class="text-body-1" dense>Oddział PTTK:</v-card-title
          ><v-card-text class="text-body-2" dense>{{
            occasion.pttk_section.name
          }}</v-card-text></v-card
        >-->
        <v-card
          ><v-card-title class="text-body-1" dense>Treść:</v-card-title
          ><v-card-text class="text-body-2" dense>{{
            item.content === null ? "-" : item.content
          }}</v-card-text></v-card
        >
        <v-card
          ><v-card-title class="text-body-1" dense
            >Dodatkowe informacje:</v-card-title
          ><v-card-text class="text-body-2" dense>{{
            item.additional_info === null ? "-" : item.additional_info
          }}</v-card-text></v-card
        >
        <v-card v-if="userLoggedIn"
          ><v-card-title class="text-body-1" dense>Rok pozyskania:</v-card-title
          ><v-card-text class="text-body-2" dense>{{
            item.obtaining_year === null ? "-" : item.obtaining_year
          }}</v-card-text></v-card
        >
        <v-card v-if="userLoggedIn"
          ><v-card-title class="text-body-1" dense>Autor:</v-card-title
          ><v-card-text class="text-body-2" dense>{{
            item.author === null ? "-" : item.author
          }}</v-card-text></v-card
        >
        <v-card v-if="userLoggedIn"
          ><v-card-title class="text-body-1" dense>Darczyńca:</v-card-title
          ><v-card-text class="text-body-2" dense>{{
            item.donor === null ? "-" : item.donor
          }}</v-card-text></v-card
        >
        <v-card v-if="userLoggedIn"
          ><v-card-title class="text-body-1" dense>Uwagi:</v-card-title
          ><v-card-text class="text-body-2" dense>{{
            item.comments === null ? "-" : item.comments
          }}</v-card-text></v-card
        >
      </v-navigation-drawer>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { getItem, getItemImagesInfo, getOccasion } from "@/shared/api";
import { getImage } from "@/shared/utils/image";

export default {
  name: "ItemDetails",
  components: {},
  data() {
    return {
      item: undefined,
      images: [],
      imagesInfo: [],
      occasion: undefined,
    };
  },
  computed: {
    ...mapGetters(["userLoggedIn"]),
    itemId() {
      return this.$route.params.id;
    },
    itemEditLink() {
      return "/editItem/" + this.itemId;
    },
    organizers() {
      return this.item.organizers
        ? this.item.organizers.map((o) => o.name).join(", ")
        : null;
    },
    occasionTypes() {
      return this.item.occasion_types
        ? this.item.occasion_types.map((o) => o.name).join(", ")
        : null;
    },
  },
  async created() {
    await this.getItemInfo();
    await this.getImagesInfo();
    await this.downloadImages();
    await this.getOccasionInfo();
  },
  methods: {
    async getItemInfo() {
      this.item = await getItem(this.itemId);
    },
    async getImagesInfo() {
      this.imagesInfo = await getItemImagesInfo(this.itemId);
    },
    async getOccasionInfo() {
      this.occasion = await getOccasion(this.item.occasion.id);
    },
    async downloadImages() {
      this.imagesInfo.map(async (info) => {
        const img = await getImage(info.id);
        this.images.push({ id: info.id, data: img });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.image-carousel {
  // align-items: center;
  // bottom: 0;
  // justify-content: center;
  // opacity: 0.8;
  // position: center;
  // width: 100%;
}
.text-md-caption {
  color: white;
}
</style>