<template>
  <div>
    <v-container class="ma-4">
      <v-row>
        <v-col style="color: red">
          <h4>
            TO JEST WERSJA TESTOWA - WSZYSTKIE DANE ZOSTANĄ USUNIĘTE PRZED
            WDROŻENIEM PRODUKCYJNYM
          </h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex child-flex" cols="2">
          <v-row>
            <v-col>
              <Summary></Summary>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-checkbox
                v-model="withImageCheckbox"
                label="Pokaż tylko ze zdjęciem"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-col>

        <v-col>
          <v-row class="ma-0">
            <v-col
              v-for="item in items"
              :key="item.id"
              class="d-flex child-flex"
              cols="2"
            >
              <ImageHoverable :item="item"></ImageHoverable>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ImageHoverable from "@/components/ImageHoverable.vue";
import Summary from "@/components/Summary.vue";
import { getItems } from "@/shared/api";

export default {
  name: "Search",
  components: {
    ImageHoverable,
    Summary,
  },
  data() {
    return {
      withImageCheckbox: false,
      bottom: false,
      fetchLocked: false,
      limit: 30,
      offset: 0,
      items: [],
    };
  },
  async created() {
    window.addEventListener("scroll", () => {
      this.bottom = this.bottomVisible();
    });
    await this.fetchItems();
  },
  methods: {
    bottomVisible() {
      const scrollY = window.scrollY;
      const visible = document.documentElement.clientHeight;
      const pageHeight = document.documentElement.scrollHeight;
      const bottomOfPage = visible + scrollY + 1 >= pageHeight;
      return !this.fetchLocked && (bottomOfPage || pageHeight < visible);
    },
    async fetchItems() {
      const response = await getItems({
        limit: this.limit,
        offset: this.offset,
        with_image: this.withImageCheckbox,
        ...this.$route.query,
      });
      this.items = this.items.concat(response.data);
      this.offset = response.offset + response.limit;

      if (response.count < response.limit || response.data.length === 0) {
        this.fetchLocked = true;
      }

      if (this.bottomVisible()) {
        await this.fetchItems();
      }
    },
    reset() {
      this.items = [];
      this.limit = 30;
      this.offset = 0;
      this.fetchLocked = false;
    },
  },
  watch: {
    bottom(bottom) {
      if (bottom) {
        this.fetchItems();
      }
    },
    async withImageCheckbox() {
      this.reset();
      await this.fetchItems();
    },
    async $route() {
      this.reset();
      await this.fetchItems();
    },
  },
};
</script>
