<template>
  <div>
    <v-card
      class="mx-auto text-lg-display-4"
      color="grey lighten-4"
      max-width="250"
    >
      <v-row dense
        ><v-btn x-small class="ma-1" plain dense @click="routeTo(0)"
          >Wszystkie eksponaty: {{ summary.total }}</v-btn
        ></v-row
      >
      <v-row dense
        ><v-btn x-small class="ma-1" plain dense @click="routeTo(1)">
          Medale: {{ summary.medal }}
        </v-btn></v-row
      >
      <v-row dense
        ><v-btn x-small class="ma-1" plain dense @click="routeTo(2)">
          Odznaki: {{ summary.odznaka }}
        </v-btn></v-row
      >
      <v-row dense
        ><v-btn x-small class="ma-1" plain dense @click="routeTo(4)">
          Odznaczenia: {{ summary.odznaczenie }}
        </v-btn></v-row
      >
      <v-row dense
        ><v-btn x-small class="ma-1" plain dense @click="routeTo(3)">
          Pieczęci: {{ summary.pieczec }}
        </v-btn></v-row
      >
      <v-row dense
        ><v-btn x-small class="ma-1" plain dense @click="routeTo(5)">
          Plakaty: {{ summary.plakat }}
        </v-btn></v-row
      >
      <v-row dense
        ><v-btn x-small class="ma-1" plain dense @click="routeTo(7)">
          Proporczyki: {{ summary.proporczyk }}
        </v-btn></v-row
      >
      <v-row dense
        ><v-btn x-small class="ma-1" plain dense @click="routeTo(6)">
          Plakietki: {{ summary.plakietka }}
        </v-btn></v-row
      >
      <v-row dense
        ><v-btn x-small class="ma-1" plain dense @click="routeTo(8)">
          Znaczki: {{ summary.znaczek }}
        </v-btn></v-row
      >
    </v-card>
  </div>
</template>

<script>
import { getSummary } from "@/shared/api";

export default {
  name: "Summary",
  data() {
    return {
      summary: {},
    };
  },
  async created() {
    this.summary = await getSummary();
  },
  methods: {
    routeTo(itemTypeId) {
      if (itemTypeId > 0) {
        this.$router.push({
          path: `/search/`,
          query: { item_type_id: itemTypeId },
        });
      } else {
        this.$router.push("/");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>