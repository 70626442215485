<template>
  <v-data-table
    :headers="headers"
    :items="tags"
    item-key="name"
    :search="search"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-dialog v-model="dialog" max-width="300px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              Dodaj
            </v-btn>
            <v-spacer></v-spacer>
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Szukaj"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.name"
                      label="Nazwa"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-btn color="blue darken-1" text @click="close"> Anuluj </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="save"> Zapisz </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="380px">
          <v-card>
            <v-card-title class="headline">
              Czy na pewno chcesz usunąć?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">
                Anuluj
              </v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                OK
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import { getTags, createTag, deleteTag } from "@/shared/api";

export default {
  name: "TagsDataTable",
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      formTitle: "Nowy tag",
      editedIndex: -1,
      editedItem: {
        name: "",
      },
      search: "",
      headers: [
        { text: "ID", value: "id", width: "15%" },
        { text: "Nazwa", value: "name", width: "70%" },
        { text: "", value: "actions", sortable: false, width: "15%" },
      ],
      tags: [],
    };
  },
  async created() {
    this.tags = await getTags();
  },
  methods: {
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async save() {
      const payload = {
        name: this.editedItem.name,
      };

      await createTag(payload);
      this.tags = await getTags();
      this.close();
    },
    deleteItem(item) {
      this.editedItem = item;
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      await deleteTag(this.editedItem.id);
      this.tags = await getTags();
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>